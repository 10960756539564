<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-14 tw-flex tw-flex-col tw-gap-3 tw-self-center tw-text-center lg:tw-mb-24">
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="600" is="div">
      <div
        class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10 tw-gap-y-20 lg:tw-flex-row lg:tw-items-start"
      >
        <div
          v-for="(feature, index) in features"
          :key="index"
          class="feature tw-group tw-flex tw-max-w-[600px] tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-rounded-[var(--rounded)] tw-text-center tw-duration-500 hover:-tw-translate-y-1 lg:tw-basis-1/3"
        >
          <div>
            <h6
              class="tw-line-clamp-2 tw-transition-all tw-duration-500 group-hover:tw-text-[var(--c-primary)] md:tw-h-[65px]"
            >
              {{ feature.title }}
            </h6>
            <p class="tw-mt-2">{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Features',

  mixins: [ComponentMixin],

  computed: {
    features() {
      return this.groupedVariables.features;
    },
  },
});
</script>

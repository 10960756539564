<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <h2 v-if="vars.titleText" class="tw-mb-12">{{ vars.titleText }}</h2>

    <div class="tw-w-full">
      <neuron-google-map-v2
        v-if="mapToDisplay && mapToDisplay.length"
        v-model:markers="mapToDisplay"
        :center="mapToDisplay[0]"
        :markerImg="mapToDisplay[0].marker"
      ></neuron-google-map-v2>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-secondary !tw-py-0"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-w-screen">
      <neuron-google-map-v2
        v-if="mapToDisplay && mapToDisplay.length"
        v-model:markers="mapToDisplay"
        :center="mapToDisplay[0]"
        :markerImg="mapToDisplay[0].marker"
      ></neuron-google-map-v2>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NeuronGoogleMapV2 from '~/components/common/NeuronGoogleMapV2.vue';

export default defineNuxtComponent({
  name: 'BranchLocation',

  mixins: [ComponentMixin],

  components: { NeuronGoogleMapV2 },

  data() {
    return {
      selectedBranch: 0,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      mapToDisplay: [],
    };
  },

  created() {
    this.initMapLocations();
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },
  },

  methods: {
    initMapLocations() {
      const branches = this.groupedVariables?.branches || [];

      if (branches && branches.length) {
        this.mapToDisplay = branches.map((branch: any, index: number) => ({
          id: `branch-${index}-${branch.id || ''}`,
          branch_id: branch.id,
          lat: branch.lat,
          lng: branch.long,
          marker: branch.marker || this.vars?.locationMarkerImage,
          version: 'v3',
          branchName: branch.name,
          color: branch.marker || 'purple',
        }));
      } else {
        this.mapToDisplay = [
          {
            id: 'single-location',
            branch_id: this.vars.branchId,
            lat: this.vars.locationLatNumber,
            lng: this.vars.locationLngNumber,
            marker: this.vars.locationMarkerImage,
            version: this.vars.mapVersionText,
            branchName: this.vars.branchNameText,
          },
        ];
      }
    },
  },
});
</script>

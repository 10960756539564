<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-10 tw-flex tw-flex-col tw-gap-3 tw-self-center tw-text-center lg:tw-mb-32">
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="600" is="div">
      <div
        class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10 lg:tw-flex-row"
      >
        <div
          v-for="(value, index) in values"
          :key="index"
          class="value tw-group tw-flex tw-max-w-[600px] tw-flex-col tw-items-center tw-justify-start tw-gap-3 tw-rounded-[var(--rounded)] tw-text-center lg:tw-h-[460px] lg:tw-basis-1/3 lg:tw-items-start lg:tw-text-start xl:tw-h-[400px]"
        >
          <NuxtImg
            :src="value.icon"
            class="tw-mb-3 tw-w-24 tw-transition-all tw-duration-500 group-hover:tw-translate-y-1"
          />
          <div>
            <h6
              class="tw-line-clamp-2 tw-h-[55px] tw-transition-all tw-duration-500 group-hover:tw-text-[var(--c-primary)] md:tw-h-[65px]"
            >
              {{ value.title }}
            </h6>
            <p
              class="tw-mt-2 tw-max-h-[260px] tw-overflow-auto xl:tw-max-h-[205px]"
              style="color: var(--c-text-primary) !important"
            >
              {{ value.description }}
            </p>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-3 tw-self-center tw-text-center">
      <h2>{{ vars.titleText }}</h2>
    </div>
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="600" is="div">
      <div
        class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-20 lg:tw-flex-row"
      >
        <div
          v-for="(value, index) in values"
          :key="index"
          class="value tw-group tw-flex tw-max-w-[550px] tw-flex-col tw-items-start tw-justify-start tw-gap-3 tw-rounded-[var(--rounded)] tw-text-start lg:tw-h-[500px] lg:tw-basis-1/3 xl:tw-h-[460px]"
        >
          <NuxtImg
            :src="value.icon"
            class="tw-mb-5 tw-w-24 tw-transition-all tw-duration-500 group-hover:tw-translate-y-1"
          />
          <div>
            <h6
              class="tw-line-clamp-2 tw-h-[48px] tw-text-[var(--c-tertiary)] tw-transition-all tw-duration-500 group-hover:tw-text-[var(--c-primary)]"
            >
              {{ value.title }}
            </h6>
            <p class="tw-mt-2 tw-max-h-[260px] tw-overflow-auto xl:tw-max-h-[205px]">
              <span class="!tw-text-3xl !tw-font-medium">{{ value.description.charAt(0) }}</span
              >{{ value.description.slice(1) }}
            </p>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurValues',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: null,
      canPaginateNext: null,
      isAllVisible: null,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    values() {
      return this.groupedVariables.values;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: var(--c-primary);
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-tertiary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>

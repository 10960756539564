<template>
  <!-- V1 Slider view if testimonials are more than 3 -->
  <section
    v-if="variant === variants.v1 && reviews?.length > 3"
    class="n-section-primary tw-overflow-hidden lg:!tw-pr-0"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-y-10 lg:tw-flex-row lg:tw-items-start lg:tw-gap-10 xl:tw-gap-20"
    >
      <!-- First div (titles) with 40% width -->
      <div class="tw-w-full tw-text-start lg:tw-w-[35%]">
        <small>{{ vars.altTitleText }}</small>
        <h2>{{ vars.titleText }}</h2>
      </div>

      <!-- Second div (slider) with 60% width -->
      <div class="tw-w-full lg:tw-w-[65%]">
        <Swiper
          v-if="reviews.length > 3"
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :pagination="true"
          :loop="true"
          :slides-per-view="1"
          :space-between="24"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
          }"
          class="lg:tw-w-[900px] xl:tw-w-[1100px] 2xl:tw-w-[1300px]"
        >
          <SwiperSlide v-for="(review, index) in reviews" :key="index">
            <div
              :key="index"
              class="tw-flex tw-h-full tw-min-h-[400px] tw-flex-col tw-gap-3 tw-border-[1px] tw-p-10 tw-text-start"
              :style="`border-radius: var(--rounded); border-color: var(--c-border);`"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <svg
                  v-for="n in review.rating"
                  :key="n"
                  width="28"
                  height="28"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                    fill="var(--c-primary)"
                  />
                </svg>
              </div>
              <p class="tw-line-clamp-[9] tw-pt-4">{{ review.content }}</p>
              <p class="n-semibold tw-mt-auto">{{ review.author }}</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="lg:tw-hidden">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v4"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </div>
  </section>

  <!-- V1 Column view if testimonials are 3 or fewer -->
  <section
    v-if="variant === variants.v1 && reviews?.length <= 3"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-flex tw-max-w-[900px] tw-flex-col tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-6 xl:tw-max-w-[1000px]"
    >
      <div class="tw-mb-10 tw-w-full tw-text-start md:tw-mb-14 md:tw-text-center">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2>{{ vars.titleText }}</h2>
      </div>
      <div v-for="(review, index) in reviews" :key="index" class="testimonial-item">
        <div class="testimonial-thumb">
          <h6>{{ review.author }}</h6>
        </div>
        <div class="testimonial-content">
          <div class="tw-mb-5 tw-flex tw-items-center tw-gap-2">
            <svg
              v-for="n in review.rating"
              :key="n"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                fill="var(--c-primary)"
              />
            </svg>
          </div>
          <p class="tw-line-clamp-[12]">
            {{ review.content }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- v1_Google -->
  <section
    v-if="variant === variants.v1_Google"
    class="n-section-primary tw-overflow-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'};`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-y-10 lg:tw-flex-row lg:tw-gap-14 xl:tw-gap-24">
      <div class="tw-w-full tw-text-start lg:tw-w-[40%]">
        <p>{{ vars.altTitleText }}</p>
        <h2>{{ vars.titleText }}</h2>
      </div>

      <div class="tw-w-full lg:tw-w-[60%]">
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :loop="true"
          :pagination="false"
          :slides-per-view="1"
          :space-between="24"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
            },
          }"
          class="tw-w-[97%] lg:tw-w-[220%]"
        >
          <SwiperSlide v-for="(review, index) in reviewsV2" :key="index">
            <div
              :key="index"
              class="tw-flex tw-h-full tw-min-h-[350px] tw-flex-col tw-gap-3 tw-border-[0.5px] tw-p-10 tw-text-start lg:tw-min-h-[400px]"
              :style="`border-radius: var(--rounded); border-color: var(--c-border);`"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <svg
                  v-for="n in review.evaluation_value"
                  :key="n"
                  width="28"
                  height="28"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                    fill="var(--c-primary)"
                  />
                </svg>
              </div>
              <p class="tw-line-clamp-[9] tw-pt-4">{{ review.content }}</p>
              <span class="tw-mt-auto tw-text-[var(--c-tertiary)]">{{ review.person_name }}</span>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="tw-mt-10">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </div>
  </section>

  <!-- V2 view -->
  <section
    v-if="variant === variants.v2"
    class="n-section-primary tw-overflow-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'};`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-y-10 lg:tw-gap-14 xl:tw-gap-20">
      <div class="tw-w-full tw-text-center">
        <h2>{{ vars.titleText }}</h2>
      </div>

      <div class="tw-w-full">
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :loop="true"
          :pagination="false"
          :slides-per-view="1"
          :space-between="24"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
            },
          }"
          class="tw-w-full lg:tw-w-[130%]"
        >
          <SwiperSlide v-for="(review, index) in reviewsV2" :key="index">
            <div
              :key="index"
              class="tw-flex tw-h-full tw-min-h-[390px] tw-flex-col tw-gap-3 tw-border-[0.5px] tw-p-10 tw-text-start lg:tw-min-h-[400px]"
              :style="`border-radius: var(--rounded); border-color: var(--c-border);`"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <svg
                  v-for="n in review.evaluation_value"
                  :key="n"
                  width="28"
                  height="28"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                    fill="var(--c-primary)"
                  />
                </svg>
              </div>
              <p class="tw-line-clamp-[9] tw-pt-4">{{ review.content }}</p>
              <span class="tw-mt-auto tw-text-[var(--c-tertiary)]">{{ review.person_name }}</span>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="tw-mt-10">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      reviewsV2: null,
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
        v1_Google: 'v1_Google',
      },
    };
  },

  created() {
    if (this.variants.v2 === this.variant || this.variants.v1_Google === this.variant) {
      const parsedReviews = this.parseFormV2(this.vars.googleReviews);
      this.reviewsV2 = parsedReviews?.reviews;
    }
  },

  mounted() {
    this.onSlideChange();
  },

  computed: {
    reviews() {
      if (this.variants.v1 === this.variant) {
        return this.groupedVariables.reviews;
      }
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (this.variant === this.variants.v1) {
        if (!this.swiper) return;
        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;
        if (isAtStart) this.canPaginateBack = false;
        else this.canPaginateBack = true;
        if (isAtEnd) this.canPaginateNext = false;
        else this.canPaginateNext = true;
      } else if (this.variant === this.variants.v2) {
        if (!this.swiper) return;
        this.canPaginateBack = true;
        this.canPaginateNext = true;
      }
    },
  },
});
</script>

<style scoped>
.testimonial-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  gap: 25px;
}
.testimonial-item:last-child {
  margin-bottom: 0;
}
.testimonial-thumb {
  position: relative;
  width: 80px;
  flex: 0 0 85px;
  margin-right: 12px;
}
.testimonial-content {
  border: 0.5px solid var(--c-border);
  border-radius: var(--rounded);
  padding: 40px;
  position: relative;
  margin-left: 12px;
}
.testimonial-content::before {
  content: '';
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  width: 16px;
  height: 16px;
  background: var(--c-section_primary);
  border-top: 0.5px solid var(--c-border);
  border-left: 0.5px solid var(--c-border);
}
.testimonial-item:nth-child(odd) {
  flex-direction: row-reverse;
}
.testimonial-item:nth-child(odd) .testimonial-thumb {
  margin-right: 0;
  margin-left: 22px;
}
.testimonial-item:nth-child(odd) .testimonial-content {
  margin-left: 0;
  margin-right: 12px;
}
.testimonial-item:nth-child(odd) .testimonial-content::before {
  left: auto;
  right: -9px;
  border-bottom: 0.5px solid var(--c-border);
  border-right: 0.5px solid var(--c-border);
  border-top: 0.5px solid transparent;
  border-left: 0.5px solid transparent;
}

@media screen and (max-width: 768px) {
  .testimonial-item {
    gap: 6px;
  }

  .testimonial-content {
    padding: 20px;
  }
}
</style>

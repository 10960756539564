<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme6/base/footer/FooterOne.vue';
import HeaderOne from '~/components/theme6/base/header/HeaderOne.vue';

// pages components
import HeroSection from '~/components/theme6/components/HeroSection.vue';
import InfoBox from '~/components/theme6/components/InfoBox.vue';
import OurValuations from '~/components/theme6/components/OurValuations.vue';
import ScrollAnimationText from '~/components/theme6/components/ScrollAnimationText.vue';
import ContentSection from '~/components/theme6/components/ContentSection.vue';
import Testimonials from '~/components/theme6/components/Testimonials.vue';
import VideoSection from '~/components/theme6/components/VideoSection.vue';
import VideoTestimonial from '~/components/theme6/components/VideoTestimonial.vue';
import OurTeam from '~/components/theme6/components/OurTeam.vue';
import SocialGallery from '~/components/theme6/components/SocialGallery.vue';
import OurGuide from '~/components/theme6/components/OurGuide.vue';
import IntroSection from '~/components/theme6/components/IntroSection.vue';
import CountSection from '~/components/theme6/components/CountSection.vue';
import OurValues from '~/components/theme6/components/OurValues.vue';
import DescriptionsBox from '~/components/theme6/components/DescriptionsBox.vue';
import Timeline from '~/components/theme6/components/Timeline.vue';
import DescriptionBanner from '~/components/theme6/components/DescriptionBanner.vue';
import OurTeamAll from '~/components/theme6/components/OurTeamAll.vue';
import ContactUs from '~/components/theme6/components/ContactUs.vue';
import BranchLocation from '~/components/theme6/components/BranchLocation.vue';
import ContactForm from '~/components/theme6/components/ContactForm.vue';
import WhyBox from '~/components/theme6/components/WhyBox.vue';
import VideoBox from '~/components/theme6/components/VideoBox.vue';
import Features from '~/components/theme6/components/Features.vue';
import AgentSection from '~/components/theme6/components/AgentSection.vue';
import NotFoundPage from '~/components/theme6/components/NotFoundPage.vue';
import AllGuides from '~/components/theme6/components/AllGuides.vue';
import CustomCookiePolicy from '~/components/theme6/components/CustomCookiePolicy.vue';
import FeeTable from '~/components/theme6/components/FeeTable.vue';
import Calendly from '~/components/theme6/components/Calendly.vue';

// library components
import FormContainer from '~/components/library/FormContainer.vue';
import Properties from '~/components/library/Properties.vue';
import Register from '~/components/common/register/Register.vue';

// common
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import Separator from '~/components/library/Separator.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';
/* import NotFoundPage from '~/components/theme4/components/NotFoundPage.vue'; */

// blogs components
import {
  Theme6BlogsPage,
  Theme6BlogsOurBlog,
  Theme6PropertiesLatestProperties,
  LibraryPropertiesOnTheFlySearchBox,
  Theme6PropertiesPropertyDetail,
  Theme6BlogsBlogDetail,
} from '#components';
import UpdateProfileEntry from '~/components/library/heads_up_alerts/UpdateProfileEntry.vue';
import LoginBox from '~/components/library/LoginBox.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    HeaderOne,
    FooterOne,
    FormContainer,
    Properties,
    CustomCookiePolicy,
    FeeTable,
    Register,
    RegisterEntry,
    PropertiesEntry,
    Calendly,
    Separator,
    RedirectTo,
    OnTheFlyPropertySearchBox: LibraryPropertiesOnTheFlySearchBox,
    PropertyDetail: Theme6PropertiesPropertyDetail,
    HeroSection,
    InfoBox,
    OurValuations,
    ScrollAnimationText,
    LatestProperties: Theme6PropertiesLatestProperties,
    ContentSection,
    Testimonials,
    VideoSection,
    VideoTestimonial,
    OurTeam,
    SocialGallery,
    OurBlog: Theme6BlogsOurBlog,
    OurGuide,
    IntroSection,
    CountSection,
    OurValues,
    DescriptionsBox,
    Timeline,
    DescriptionBanner,
    OurTeamAll,
    BlogsPage: Theme6BlogsPage,
    BlogDetail: Theme6BlogsBlogDetail,
    ContactUs,
    BranchLocation,
    ContactForm,
    WhyBox,
    VideoBox,
    Features,
    AgentSection,
    NotFoundPage,
    UpdateProfileEntry,
    LoginBox,
    AllGuides,
  },
});
</script>

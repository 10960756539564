<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div v-if="variants.v1 === variant" class="tw-w-full tw-text-center">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2 class="tw-mb-8">{{ vars.titleText }}</h2>
      </div>

      <div
        class="tw-items- tw-flex tw-flex-col tw-items-center tw-gap-24 tw-text-center lg:tw-flex-row lg:tw-items-stretch"
      >
        <div
          v-for="(card, index) in cards"
          :key="`layout-card-${index}`"
          class="tw-flex tw-max-w-lg tw-flex-1 tw-basis-1/5 tw-flex-col tw-items-center tw-justify-center"
        >
          <div class="tw-flex tw-h-48 tw-w-48 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full">
            <LibraryImage :h-ratio="1" :w-ratio="1" :src="card.photo" />
          </div>

          <h6 class="tw-mt-6 tw-line-clamp-2 tw-h-[70px]">{{ card.title }}</h6>

          <p class="tw-mb-4 tw-line-clamp-[8]">
            {{ card.description }}
          </p>

          <nuxt-link
            :external="true"
            :class="`n-${card.button_type} n-btn tw-mt-auto`"
            :to="card.button_link"
            v-if="card.button_link"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ card.button_label }}</div>
                <div class="n-btn-t2">{{ card.button_label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
    <div v-else-if="variants.v2 === variant" class="tw-w-full tw-text-center">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <h2 class="tw-mb-10">{{ vars.titleText }}</h2>
      </div>

      <div
        class="tw-items- tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-gap-24 tw-text-center lg:tw-flex-row lg:tw-items-stretch"
      >
        <div
          v-for="(card, index) in cards"
          :key="`layout-card-${index}`"
          class="tw-flex-grow-1 tw-flex tw-max-w-[450px] tw-flex-1 tw-basis-1/4 tw-flex-col tw-items-start tw-justify-center tw-text-start"
        >
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-overflow-hidden"
            style="border-radius: var(--rounded)"
          >
            <LibraryImage :h-ratio="1" :w-ratio="1" :src="card.photo" />
          </div>

          <h6 class="tw-mt-10 tw-line-clamp-2 tw-h-[70px]">{{ card.title }}</h6>

          <p class="tw-mb-8 tw-line-clamp-[8]">
            {{ card.description }}
          </p>

          <nuxt-link
            :external="true"
            :class="`n-${card.button_type} n-btn tw-mt-auto`"
            :to="card.button_link"
            v-if="card.button_link"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ card.button_label }}</div>
                <div class="n-btn-t2">{{ card.button_label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>

    <div v-else-if="variants.v3 === variant" class="tw-w-full tw-text-center">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2 class="tw-mb-8">{{ vars.titleText }}</h2>
      </div>

      <MotionGroup
        is="div"
        preset="slideVisibleBottom"
        :duration="800"
        class="tw-items- tw-flex tw-flex-col tw-items-center tw-gap-6 tw-text-center md:tw-gap-12 lg:tw-flex-row lg:tw-items-stretch"
      >
        <div
          v-for="(card, index) in cards"
          :key="`layout-card-${index}`"
          class="valuation-card tw-flex tw-w-full tw-max-w-lg tw-flex-1 tw-basis-1/5 tw-flex-col tw-items-center tw-justify-start tw-p-6"
          style="border-radius: var(--rounded)"
        >
          <h4 class="tw-mb-2">{{ card.title }}</h4>
          <span class="tw-line-clamp-2 tw-h-[70px]">{{ card.alt_title }}</span>

          <div class="tw-flex tw-h-full tw-flex-col tw-gap-6">
            <div v-html="card.descriptions"></div>
            <div class="tw-mt-auto tw-pb-4" v-html="card.bottom_descriptions"></div>
          </div>
          <nuxt-link
            :external="true"
            :class="`n-${card.button_type} n-btn tw-mt-auto`"
            :to="card.button_link"
            v-if="card.button_link"
          >
            <div class="n-btn-container">
              <div class="n-btn-group">
                <div class="n-btn-t1">{{ card.button_label }}</div>
                <div class="n-btn-t2">{{ card.button_label }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </MotionGroup>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>

<style scoped>
.valuation-card:nth-child(1) {
  background-color: var(--c-valuation_card);
}
.valuation-card:nth-child(2) {
  background-color: #ffb710;
}
.valuation-card:nth-child(3) {
  background-color: var(--c-valuation_card);
  background-color: var(--c-section_primary);
  border: 1px solid var(--c-border);
}
</style>

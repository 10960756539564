<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div v-if="variants.v1 === variant" class="tw-w-full tw-text-center">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2 class="tw-mb-8">{{ vars.titleText }}</h2>
      </div>

      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-24 tw-text-center lg:tw-flex-row lg:tw-items-stretch"
      >
        <div
          v-for="(card, index) in cards"
          :key="`layout-card-${index}`"
          class="tw-flex tw-flex-1 tw-basis-1/5 tw-flex-col tw-items-center tw-justify-center"
          v-html="card.calendly"
        ></div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
